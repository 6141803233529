import React, {useState} from "react";
import {Button, Card, FormControl, InputGroup, Modal, ProgressBar} from "react-bootstrap";
import './styles.css';

const SaleCard = React.memo((props) => {

    const { status, startsIn, totalAllocation, startDate, endDate, price, minimumAllocation, percent } = props;
    const [modalShow, setModalShow] = useState(false);

    const MyVerticallyCenteredModal = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="p-5 text-center d-flex justify-content-center align-items-center flex-column">
                    <h4>Buy Token</h4>
                    <div className="price mt-4 mb-4">
                        <h6>Price</h6>
                        <h4>0.00000</h4>
                    </div>
                    <h6 className="min-max-price">
                        Min 0.01 BNB and Maximum 25 BNB
                    </h6>
                    <InputGroup className="mb-4 mt-1">
                        <FormControl aria-label="max" placeholder="0.00"/>
                        <InputGroup.Text className="span">MAX</InputGroup.Text>
                    </InputGroup>
                    <div>
                        <Button className="btn-cancel me-2" onClick={() => props.onHide()}>Cancel</Button>
                        <Button className="btn-buy">Buy</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Card className="sale-card">
            <Card.Body>
                <div className="timer pt-2">
                    <div className="status mb-2">
                        {status ? "Starts In" : "Coming Soon"}
                    </div>
                    <h6 className="text-muted">{startsIn ?? "-"}</h6>
                </div>

                <div className="allocation mt-4">
                    <h6>Total Allocation</h6>
                    <h4>{totalAllocation ?? "-"}</h4>
                </div>

                <div className="date d-flex justify-content-between align-items-center mt-4">
                    <div>
                        <h6>Start Date</h6>
                        <h4>{startDate ?? "-"}</h4>
                    </div>
                    <div>
                        <h6>End Date</h6>
                        <h4>{endDate ?? "-"}</h4>
                    </div>
                </div>

                <div className="price d-flex justify-content-between align-items-center mt-4">
                    <div>
                        <h6>Price</h6>
                        <h4>{price ?? "-"}</h4>
                    </div>
                    <div>
                        <h6>Max Allocation</h6>
                        <h4>{minimumAllocation ?? "-"}</h4>
                    </div>
                </div>

                <hr/>

                <Button className="get-token-btn" onClick={() => setModalShow(true)}>
                    Get COLT
                </Button>

                <div className="mt-3">
                    <ProgressBar now={percent} label={`${percent}%`} />
                </div>

            </Card.Body>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Card>
    );
});

export default SaleCard;
