import React, { useEffect } from "react";
import "./styles.css";
import $ from "jquery";

export const HexaOverlayAnimated = (props) => {

    const { animated } = props;

    useEffect(() => {
        var tile	=	$('.tile'),
            speed	=	1000;

        setInterval(function(){

            tile.attr('class', 'tile');

            //Get Random position from hexagons
            var tileRm		=	Math.floor(Math.random() * tile.length);
            tile.eq(tileRm).attr('class', 'tile active');

            //console
            console.log(tile.eq(tileRm));
        }, speed);
    }, []);

    return (
        <svg className="hexagons" width="600" height="265" viewBox="0 0 450 210">
            <g transform="translate(300,132.5)">
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-333.41978045700887,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-363.73066958946424,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-272.7980021920981,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-303.1088913245535,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-333.41978045700887,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-212.17622392718746,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-242.4871130596428,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-272.7980021920981,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-303.1088913245535,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-151.55444566227678,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-181.86533479473212,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-212.17622392718746,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-242.4871130596428,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-90.93266739736606,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-121.2435565298214,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-151.55444566227675,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-181.86533479473212,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-30.31088913245535,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-60.6217782649107,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-90.93266739736606,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-121.2435565298214,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(30.31088913245535,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(0,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-30.31088913245535,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(-60.6217782649107,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(90.93266739736606,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(60.6217782649107,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(30.31088913245535,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(0,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(151.55444566227675,52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(121.2435565298214,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(90.93266739736606,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(60.6217782649107,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(181.86533479473212,0)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(151.55444566227678,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(121.24355652982142,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(212.17622392718746,-52.5)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
                <g className={`${animated ? 'tile' : 'hexa'}`} transform="translate(181.86533479473212,-105)">
                    <polygon
                        points="35.000,0.000 17.500,30.311 -17.500,30.311 -35.000,0.000 -17.500,-30.311 17.500,-30.311"
                        transform="rotate(-30)"></polygon>
                </g>
            </g>
        </svg>
    );
};
