import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import "../assets/privacyPolicy.css";
import policyLockIcon from "../assets/images/policy-lock.png";

export default function PrivacyPolicy() {
    return (
        <div id="app-content">
            <Header />
            <div className="privacy-policy section">
                <div className="heading section">
                    <div className="container mb-5 mt-5 d-flex justify-content-between align-items-center">
                        <div>
                            <h1>Privacy Policy</h1>
                            <h6>Last updated: February 2, 2022</h6>
                        </div>
                        <div>
                            <img src={policyLockIcon} alt="" />
                        </div>
                    </div>
                </div>

                <div className="container">

                    <p className="top-text mt-5">
                        Herein is provided the privacy policy of Collectivly (owner and operator of Collectivly.io) This Privacy Policy describes how Collectivly.io
                        will manage end user’s personal information which we collect as a result of your use of Collectivly.io, your use of our websites located at
                        https://Collectivly.io and any pages or websites under the 'Collectivly' brand including any tablet or mobile phone
                        applications that are owned and/or operated by us or whenever we otherwise deal with you. Collectivly is committed to ensuring that the personal information
                        we collect about you is protected and is used, stored and disclosed in accordance with the Privacy Act and this Privacy Policy.
                    </p>

                    <h3 className="mt-5">Website Use</h3>
                    <p>
                        In using the website, you agree to be bound by the terms of this Privacy Policy. Collectivly.io may review the Privacy Policy periodically
                        and reserves the right to change the Privacy Policy at any time at its discretion by posting such changes on the website. Changes will be
                        effective immediately when posted on the website. The end users’ continued use of the website following posting will constitute your acceptance
                        of those changes.
                    </p>
                    <p>
                        This Privacy Policy will not apply to websites which are linked to the website and over which Collectivly.io has no control.
                    </p>

                    <h3 className="mt-5">Personal Information</h3>
                    <p>
                        The kinds of Personal Information that Collectivly.io may collect from you is: <br/><br/>
                        <span>
                            - <br/>
                        Name <br/>
                        Email address <br/>
                        Personally submitted preferences <br/>
                        Location data <br/>
                        IP address <br/>
                        </span>
                    </p>

                    <h3 className="mt-5">Collection Purposes</h3>
                    <p>
                        Collectivly.io will collect your Personal Information only by lawful and fair means and not in an intrusive way to operate its Service, and to
                        provide you with the following information:
                    </p>
                    <ul>
                        <li>If you wish to subscribe to Collectivly.io's weekly newsletter or other marketing communications.</li>
                        <li>To update you on technical progress of Collectivly.</li>
                        <li>To provide services to you as a User.</li>
                        <li>To identify you as a User for security purposes and to comply with our legal obligations.</li>
                        <li>To maintain your User account.</li>
                        <li>To upgrade and enhance your experience within the website or over the telephone, or to tailor or develop information, services or products to suit your needs which may include market research and conducting promotions.</li>
                        <li>To tell you about our products or services that we think may be of interest to you by communicating with you via email, SMS or telephone.</li>
                        <li>To create aggregate data about Users through demographic profiling, statistical analysis of the database to provide to potential and existing Collectivly holders, and to allow for more efficient operation of Collectivly.io’s business.</li>
                        <li>To comply with Collectivly.io’s legal and statutory obligations.</li>
                        <li>For taking appropriate action if Collectivly.io has reason to suspect that unlawful activity or misconduct of a serious nature has been, is being or may be engaged in that relates to our functions and activities.</li>
                        <li>To establish, exercise or defend any legal claims.</li>
                        <li>Manage job applications.</li>
                    </ul>
                    <p>
                        You may choose to deal with us on an anonymous basis or to use a pseudonym unless it is not practicable for us to deal with individuals who we cannot identify
                        or we are required by law to only deal with identified individuals. Also, if you do not provide Collectivly.io with the Personal Information we request, we
                        may be unable to process your request to become a User, provide you with our services or respond to your enquiry or complaint. Examples of Personal Information
                        that may be collected by Collectivly.io include your name, email address, telephone numbers, your date of birth, other verification information such as your
                        Service usage activities. By becoming a User or otherwise choosing to provide Collectivly.io with Personal Information you consent to Collectivly.io collecting,
                        using and disclosing your Personal Information for the above purposes.
                    </p>

                    <h3 className="mt-5">Direct Marketing and Opting Out</h3>
                    <p>
                        Occasionally we may use your Personal Information to inform you about Collectivly.io's products or services or about promotional activities which
                        Collectivly.io believes may be of interest or of benefit to you. We may do this via email, SMS, telephone or mail. If you no longer wish to receive
                        marketing or promotional material from Collectivly.io at all or in any particular form, you may contact us at any time by email to support@Collectivly.io
                        with your request with which we will comply as soon as is practical.
                    </p>
                    <p>
                        From time to time we may contact you in relation to the management and administration of your Collectivly.io account. These communications can be
                        via any of the modes of contact recorded when registering as a User. Such communication does not affect your opt-in or opt-out status for direct
                        marketing communications.
                    </p>

                    <h3 className="mt-5">Management of your Personal Information</h3>
                    <p>
                        Collectivly.io will take all reasonable steps to ensure that the Personal Information which it collects, uses or discloses is correct and is stored in a
                        secure environment which is accessed only by authorised persons.
                    </p>
                    <p>
                        Collectivly.io will destroy or permanently de-identify the Personal Information we hold when it is no longer required for any purpose permitted under
                        the APPs including our legal or operational obligations.
                    </p>

                    <h3 className="mt-5">Security of Personal Information</h3>
                    <p>
                        You acknowledge that no data transmission over the Internet is totally secure. Accordingly, Collectivly.io does not warrant the security of any
                        information which you transmit to it. Any information which you transmit to Collectivly.io is transmitted at your own risk. However,
                        once Collectivly.io receives your transmission, Collectivly.io will take reasonable steps to protect your Personal Information from misuse,
                        loss and unauthorised access, modification and disclosure including by using password protected systems and databases and Secure Socket Layer (SSL) technology.
                    </p>
                    <p>
                        Collectivly.io’s employees, agents and contractors are required to maintain the confidentiality of Users’ Personal Information and trading behaviour.
                    </p>
                    <p>
                        Information posted on bulletin boards or communicated within a social media environment (for example, Facebook, Twitter, Chat Rooms) becomes public
                        information. Collectivly.io cannot guarantee the security of this type of disclosed information.
                    </p>
                    <p>
                        We take seriously the responsibility to exclude children from access to our services. We will not accept their information for the purposes of accessing
                        or using the Service. It is however, ultimately the responsibility of parents or guardians to monitor their children’s Internet activities including where
                        appropriate by using Internet screening software.
                    </p>
                    <p>
                        Remember to always log out of your account when you have completed your time on the website. This is particularly important if you share a computer with
                        another person. You are responsible for the security of and access to your own computer, mobile device or any other handset used to access the website.
                    </p>
                    <p>
                        Ultimately, you are solely responsible for maintaining the secrecy of your username, password and any account information. Please be careful whenever
                        using the Internet and our website.
                    </p>

                    <h3 className="mt-5">Access to Personal Information</h3>
                    <p>
                        You may access the Personal Information collected by Collectivly.io by contacting us at support@Collectivly.io. We will give you access to your
                        Personal Information in the manner requested if that is possible and within a reasonable period. If we refuse your request or cannot give you access
                        in the manner you have requested, we will do what we can to meet your requirements by other means. We may not be required to give you access to your
                        Personal Information in certain circumstances which are set out in the APPs including where it may have an unreasonable impact on other individual's
                        privacy. If we refuse access for such reasons, we will advise you in writing of the refusal and our reasons and the complaint mechanisms available to you.
                    </p>

                    <h3 className="mt-5">Use of Website</h3>
                    <p>
                        By using this website, you agree to Collectivly.io collecting and processing your Personal Information on the basis explained in this Privacy Policy.
                    </p>

                    <h3 className="mt-5">Contact Details</h3>
                    <p>
                        If you have any queries, requests for access or correction or complaints relating to the handling of your personal information, please contact us by email.
                    </p>
                    <a href="mailto:support@Collectivly.io">support@Collectivly.io</a>

                    <h3 className="mt-5">Updates to this Privacy Policy</h3>
                    <p className="mb-5">
                        Collectivly.io may review, change and update this Privacy Policy to reflect our current practices and obligations. We will publish our current
                        Privacy Policy on our website at www.Collectivly.io and the changes will take effect at the time of publishing. You should review this privacy
                        policy regularly and remain familiar with its terms.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
