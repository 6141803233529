import React from "react";
import { Card } from "react-bootstrap";
import './styles.css';

const TierCard = React.memo((props) => {
    const { tier, stakingRequirement, participationRequirements, nftAccess } = props;

    return (
        <Card className="tier-card">
            <Card.Body className="pt-5 pb-5">
                <h2>Tier {tier}</h2>
                <hr />
                <h5 className="pt-2">Staking Requirement</h5>
                <h4 className="pb-4">{stakingRequirement}</h4>

                <h5>Participation Requirement</h5>
                {participationRequirements ? (
                    <h6 className="pb-4">Twitter: Like, Follow & Retweet <br /> Telegram: Follow</h6>
                ) : (
                    <h6 className="pb-4 none">None</h6>
                )}

                <h5>NFT Access</h5>
                <h6 className="pb-2">{nftAccess}</h6>
                <hr />
            </Card.Body>
        </Card>
    );
});

export default TierCard;
