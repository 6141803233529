import React, { useState } from "react";
import {Badge, Tab, Tabs} from "react-bootstrap";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import CollectionCard from "../components/CollectionCard/CollectionCard";
import "../assets/project.css";

// IMAGES
import EthereumSign from '../assets/images/Demo-Project/ethereum-sign.png';
import ProjectImage from '../assets/images/Demo-Project/project.png';
import ProjectAvatar from '../assets/images/Demo-Project/avatar.png';
// NFT COLLECTION IMAGES
import exclusive1 from '../assets/images/NFTs/exclusive/K4M-01.png';
import exclusive2 from '../assets/images/NFTs/exclusive/K4M-02.png';
import exclusive4 from '../assets/images/NFTs/exclusive/K4M-04.png';
import exclusive6 from '../assets/images/NFTs/exclusive/K4M-06.png';
import exclusive7 from '../assets/images/NFTs/exclusive/K4M-07.png';
import rare1 from '../assets/images/NFTs/rare/0N1-1.png';
import rare2 from '../assets/images/NFTs/rare/0N1-2.png';
import rare3 from '../assets/images/NFTs/rare/0N1-3.png';
import rare4 from '../assets/images/NFTs/rare/0N1-4.png';
import rare5 from '../assets/images/NFTs/rare/0N1-5.png';
import rare6 from '../assets/images/NFTs/rare/0N1-6.png';
import rare7 from '../assets/images/NFTs/rare/0N1-7.png';
import common1 from '../assets/images/NFTs/common/0N1-1.png';
import common2 from '../assets/images/NFTs/common/0N1-2.png';
import common3 from '../assets/images/NFTs/common/0N1-3.png';
import common4 from '../assets/images/NFTs/common/0N1-4.png';
import common5 from '../assets/images/NFTs/common/0N1-5.png';
import common6 from '../assets/images/NFTs/common/0N1-6.png';
import common7 from '../assets/images/NFTs/common/0N1-7.png';
import common8 from '../assets/images/NFTs/common/0N1-8.png';
import common9 from '../assets/images/NFTs/common/0N1-9.png';
import common10 from '../assets/images/NFTs/common/0N1-10.png';

export default function Project() {

    const [activeTab, setActiveTab] = useState('all');

    const exclusiveCollection = [
        {name: 'Item 1', image: exclusive1, rarity: 'Exclusive', startingPrice: '25 ETH'},
        {name: 'Item 2', image: exclusive2, rarity: 'Exclusive', startingPrice: '25 ETH'},
        {name: 'Item 3', image: exclusive4, rarity: 'Exclusive', startingPrice: '25 ETH'},
        {name: 'Item 4', image: exclusive6, rarity: 'Exclusive', startingPrice: '25 ETH'},
        {name: 'Item 5', image: exclusive7, rarity: 'Exclusive', startingPrice: '25 ETH'},
    ];

    const rareCollection = [
        {name: 'Item 1', image: rare1, rarity: 'Rare', startingPrice: '1.2 ETH'},
        {name: 'Item 2', image: rare2, rarity: 'Rare', startingPrice: '1.2 ETH'},
        {name: 'Item 3', image: rare3, rarity: 'Rare', startingPrice: '1.2 ETH'},
        {name: 'Item 4', image: rare4, rarity: 'Rare', startingPrice: '1.2 ETH'},
        {name: 'Item 5', image: rare5, rarity: 'Rare', startingPrice: '1.2 ETH'},
        {name: 'Item 6', image: rare6, rarity: 'Rare', startingPrice: '1.2 ETH'},
        {name: 'Item 7', image: rare7, rarity: 'Rare', startingPrice: '1.2 ETH'},
    ];

    const commonCollection = [
        {name: 'Item 1', image: common1, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 2', image: common2, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 3', image: common3, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 4', image: common4, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 5', image: common5, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 6', image: common6, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 7', image: common7, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 8', image: common8, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 9', image: common9, rarity: 'Common', startingPrice: '0.2 ETH'},
        {name: 'Item 10', image: common10, rarity: 'Common', startingPrice: '0.2 ETH'},
    ];

    const allCollections = [
        ...exclusiveCollection,
        ...rareCollection,
        ...commonCollection
    ];

    return (
        <div id="app-content">
            <Header />

            <div className="project-page section">
                <div className="container pt-5 pb-5">
                    <div className="row">
                        <div className="info col-12 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img className="avatar img-fluid" alt="" src={ProjectAvatar}/>
                                <div className="ms-4">
                                    <h1>Demo Project</h1>
                                    <h4 className="m-0">Items: <span>7.777</span></h4>
                                </div>
                            </div>
                            <div className="currency d-flex flex-column align-items-center">
                                <Badge bg="light" text="dark">DEMO APP</Badge>
                                <div className="d-flex align-items-center mt-3">
                                    <h2 className="m-0 me-2">ETH</h2>
                                    <img className="sign img-fluid" alt="" src={EthereumSign}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 mt-5 mb-5">
                            <img className="project-img img-fluid" alt="" src={ProjectImage}/>
                        </div>
                        <div className="col-12 col-lg-4 mt-0 mt-lg-5 mb-5">
                            <div className="total-raise p-4 p-xl-5">
                                <h5>Total Items</h5>
                                <h1>7.777</h1>
                                <h5 className="mt-4">Access</h5>
                                <h1 className="m-0">All Tiers</h1>
                            </div>
                        </div>
                        <div className="nft-collections col-12">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={activeTab}
                                onSelect={(tab) => setActiveTab(tab)}
                                className="mb-3"
                            >
                                <Tab eventKey="all" title="All">
                                    <h1 className="mt-5">All Collections</h1>

                                    <div className="collection">
                                        <div className="row">
                                            {allCollections.map((item, index) => {
                                                return (
                                                    <div className="col-md-6 col-lg-4 col-xl-3 mt-4 mb-4">
                                                        <CollectionCard item={item} index={index} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="exclusive" title="Exclusive">
                                    <h1 className="mt-5">Exclusive</h1>
                                    <h3 className="d-flex">25 ETH <img className="ms-2" alt="" src={EthereumSign}/></h3>

                                    <div className="collection">
                                        <div className="row">
                                            {exclusiveCollection.map((item) => {
                                                return (
                                                    <div className="col-md-6 col-lg-4 col-xl-3 mt-4 mb-4">
                                                        <CollectionCard item={item} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="rare" title="Rare">
                                    <h1 className="mt-5">Rare</h1>
                                    <h3 className="d-flex">1.2 ETH <img className="ms-2" alt="" src={EthereumSign}/></h3>

                                    <div className="collection">
                                        <div className="row">
                                            {rareCollection.map((item) => {
                                                return (
                                                    <div className="col-md-6 col-lg-4 col-xl-3 mt-4 mb-4">
                                                        <CollectionCard item={item} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="common" title="Common">
                                    <h1 className="mt-5">Common</h1>
                                    <h3 className="d-flex">0.2 ETH <img className="ms-2" alt="" src={EthereumSign}/></h3>

                                    <div className="collection">
                                        <div className="row">
                                            {commonCollection.map((item) => {
                                                return (
                                                    <div className="col-md-6 col-lg-4 col-xl-3 mt-4 mb-4">
                                                        <CollectionCard item={item} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}
