import React from "react";
import { Button } from "react-bootstrap";
import './styles.css';
import EthereumSign from "../../assets/images/Demo-Project/ethereum-sign.png";

const CollectionCard = React.memo((props) => {
    const { item, index } = props;

    return (
        <div className="collection-card p-3">
            <div className="collection-rarity">
                <h5 className="m-0 rarity">Rarity:</h5>
                <h5>{item.rarity}</h5>
            </div>
            <div className="collection-image mt-3 mb-3">
                <img className="img-fluid" alt="" src={item.image}/>
            </div>
            <div className="collection-name">
                <h4>Item {index + 1} <img className="ms-2" alt="" src={EthereumSign}/></h4>
            </div>
            <div className="collection-pricing mt-3">
                <h5>Starting Price: <span>{item.startingPrice}</span></h5>
            </div>
            <Button className="bid-btn">Place a Bid</Button>
        </div>
    );
});

export default CollectionCard;
