import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";

// Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ProjectCard from "./components/ProjectCard/ProjectCard";
import TierCard from "./components/TierCard/TierCard";
import { HexaOverlayAnimated } from "./components/HexaOverlay/HexaOverlayAnimated";

// Stylesheets
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles.css';

// Images
import MainImage from './assets/images/main-img.png';
import BinanceNetwork from './assets/images/Networks/binance.png';
import EhereumNetwork from './assets/images/Networks/ehereum.png';
import ProjectImage from './assets/images/Demo-Project/project.png';
import ProjectAvatar from './assets/images/Demo-Project/avatar.png';
import EthereumSign from './assets/images/Demo-Project/ethereum-sign-grey.png';
import AboutImage from './assets/images/about-img.png';

export default function App() {

    const navigate = useNavigate();

    const demoProjects = [
        {
            name: "Demo Project",
            currency: "$ETH",
            totalItems: "7.777",
            access: "All Tiers",
            signImage: EthereumSign,
            projectImage: ProjectImage,
            avatar: ProjectAvatar,
            status: "DEMO",
        },
    ];

    const tiersData = [
        {tier: '1', stakingRequirement: '2,500', participationRequirements: true, nftAccess: 'Common'},
        {tier: '2', stakingRequirement: '10,000', participationRequirements: true, nftAccess: 'Common & Rare'},
        {tier: '3', stakingRequirement: '25,000', participationRequirements: false, nftAccess: 'Common, Rare & Exclusive'},
    ];

    // const teamData = [
    //     {name: 'Strawberry / Ichigo', role: '// Co-CEO, Front-end Design', image: MemberImage},
    //     {name: 'Cryptorious', role: '// Co-CEO, Front-end Design', image: MemberImage},
    //     {name: 'Imcmxcs / Ichigo', role: '// Co-CEO, Front-end Design', image: MemberImage},
    //     {name: 'Strawberry / Ichigo', role: '// Co-CEO, Front-end Design', image: MemberImage},
    //     {name: 'Imcmxcs', role: '// Co-CEO, Front-end Design', image: MemberImage},
    //     {name: 'Cryptorious', role: '// Co-CEO, Front-end Design', image: MemberImage},
    // ];

    return (
        <div id="app-content" className="color-bg">
            <Header/>

            <div className="main-section section pt-5 pb-5">
                <div className="container">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md-12 col-lg-7 col-xl-8 d-flex flex-column justify-content-center align-self-start mt-5 pt-5">
                            <h5 className="nft-text">NFT Pre-Sales</h5>
                            <h1 className="text-uppercase">Setting The <span>Standard</span></h1>
                            <h6 className="pt-4 pb-4">Pre-sales of the finest NFT collections. <br/> Reviewed & selected by our team industry experts.</h6>
                            <div className="d-flex align-items-center flex-column d-md-block">
                                <a href="https://t.me/Collectivly_Official" target="_blank" rel="noreferrer">
                                    <Button className="button-primary me-0 me-md-2 mb-3 mb-md-0">
                                        Join the Community
                                        <FontAwesomeIcon className="ms-2" icon={faTelegramPlane} />
                                    </Button>
                                </a>
                                <Button className="button-secondary" onClick={() => navigate('dashboard')}>Go to PRE-SALE</Button>
                            </div>
                        </div>
                        <div className="col-md-12 illustration-img col-lg-5 col-xl-4 pt-5">
                            <img className="img-fluid animation-bounce-7 animation-duration" alt="" src={MainImage} />
                        </div>
                        <div className="col-12 pt-5 networks">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-12 text-center mb-4">
                                    <h2 className="text-uppercase">Powered by</h2>
                                </div>
                                <div className="col-2 d-flex justify-content-center">
                                    <img className="img-fluid" alt="" src={BinanceNetwork} />
                                </div>
                                <div className="col-2 d-flex justify-content-center">
                                    <img className="img-fluid" alt="" src={EhereumNetwork} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="projects" className="demo-projects section pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <h1 className="section-heading pt-5 pb-5">Upcoming Projects</h1>
                        {demoProjects.map((data) => {
                            return (
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <ProjectCard
                                        name={data.name}
                                        currency={data.currency}
                                        totalItems={data.totalItems}
                                        access={data.access}
                                        signImage={data.signImage}
                                        projectImage={data.projectImage}
                                        avatar={data.avatar}
                                        status={data.status}
                                        onClick={() => navigate('project')}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="about-section section pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <h1 className="section-heading pt-5 pb-5">About Collectivly</h1>
                        <div className="col-md-12 col-lg-7 mb-5">
                            <h6>What is COLLECTIVLY?</h6>
                            <h5 className="mt-3">COLLECTIVLY is the launchpad for pre-sales of the finest NFT Collections.</h5>
                            <p>Collectivly’s unique pre-sale system offers certainty and transparency to investors and provides NFT projects the opportunity to
                                gain awareness and securely raise starting capital.</p>

                            <h6 className="mt-4">Why use COLLECTIVLY?</h6>
                            <h5 className="mt-3">COLLECTIVLY is the solution to the major problems NFT investors encounter.</h5>
                            <p>
                                The current NFT market is flooded with risky investments and sometimes even
                                straight-up scams. But finding the right, reliable NFT projects in this maze is a
                                time-consuming and difficult, but necessary process. Since too many projects
                                have proven to be unreliable.
                                <br/>
                                <br/>
                                All projects launching on Collectivly are reviewed and selected by our team of
                                industry experts. They spend their time researching & validating projects and
                                their founders, thus saving Collectivly members valuable time and mitigating the
                                risk of lost investments.
                            </p>
                        </div>
                        <div className="col-md-12 col-lg-5 mb-5 d-flex align-items-center">
                            <img className="img-fluid about-img" src={AboutImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="tier-section section pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <h1 className="section-heading pt-5 pb-5">Tier System</h1>
                        {tiersData.map((data) => {
                            return (
                                <div className="col-md-6 col-lg-4 pb-5">
                                    <TierCard
                                        tier={data.tier}
                                        stakingRequirement={data.stakingRequirement}
                                        participationRequirements={data.participationRequirements}
                                        nftAccess={data.nftAccess}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className="partner-section section pt-5 pb-5">
                <HexaOverlayAnimated animated={true} />
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-12">
                            <h1 className="section-heading mb-4">Partners</h1>
                        </div>
                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                            <p className="section-sub-heading mb-5">Our trusted partners helping us build the future</p>
                            <h1 className="pt-5 journey-text">Join our Journey</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="team-section section pt-5 pb-5">*/}
            {/*    <div className="container">*/}
            {/*        <h1 className="section-heading pt-5">The Team</h1>*/}
            {/*        <h6 className="section-sub-heading pb-5">The team behind Collectivly</h6>*/}
            {/*        <div className="team-cards row pt-4 pb-2 mb-5">*/}
            {/*            {teamData.map((data) => {*/}
            {/*                return (*/}
            {/*                    <div className="col-md-6 col-lg-4 d-flex justify-content-center align-items-center">*/}
            {/*                        <TeamCard name={data.name} role={data.role} image={data.image}/>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Footer/>
        </div>
    );
}
