import { render } from "react-dom";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import ScrollToTop from "./config/ScrollToTop";
import App from "./App";
import Dashboard from "./routes/dashboard";
import Project from "./routes/project";
import PageNotFound from "./routes/404";
import PrivacyPolicy from "./routes/privacyPolicy";
import TermsCondition from "./routes/termsCondition";

const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="project" element={<Project />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<TermsCondition />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    </BrowserRouter>,
    rootElement
);
