import React, { useState } from "react";
import {Button, Card, Tab, Tabs} from "react-bootstrap";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SaleCard from "../components/SaleCard/SaleCard";
import StakeCard from "../components/StakeCard/StakeCard";
import '../assets/dashboard.css';

export default function Dashboard() {

    const [activeTab, setActiveTab] = useState('pre-sale');
    // const [isStaked, setStaked] = useState(false);

    // const onStakeLock = () => {
    //     setStaked(true);
    // }

    return (
        <div id="app-content">
            <Header />

            <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
                className="mb-3 dashboard-tabs"
            >
                <Tab eventKey="activity" title="Activity" >
                    <div className="claim-section section">
                        <div className="container mb-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                    <h3>Activity</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="pre-sale" title="Pre-Sale">
                    <div className="sale-section section">
                        <div className="container-fluid pt-5 pb-5 mb-5">
                            <div className="row text-center d-flex justify-content-center align-items-center">
                                <h3 className="mb-5">SALE ROUNDS</h3>
                                <div className="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column align-items-center d-md-block">
                                    <div className="sale-tab">
                                        Private Sale
                                    </div>
                                    <SaleCard
                                        status="active"
                                        startsIn="00:00:00"
                                        totalAllocation="TBA"
                                        startDate="TBA"
                                        endDate="TBA"
                                        price="TBA"
                                        minimumAllocation="TBA"
                                        percent="0"
                                    />
                                </div>
                                <div className="col-12 col-lg-9 col-xl-6">
                                    <div className="row card-group">
                                        <div className="col-12 d-flex flex-column align-items-center d-md-block">
                                            <div className="sale-tab parent-tab mt-4 mt-lg-0">
                                                Public Sale
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 d-flex flex-column align-items-center d-md-block">
                                            <div className="sale-tab child-tab mt-1 mt-md-0">
                                                Round 1
                                            </div>
                                            <SaleCard
                                                status="active"
                                                startsIn="00:00:00"
                                                totalAllocation="TBA"
                                                startDate="TBA"
                                                endDate="TBA"
                                                price="TBA"
                                                minimumAllocation="TBA"
                                                percent="0"
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 d-flex flex-column align-items-center d-md-block">
                                            <div className="sale-tab child-tab mt-3 mt-md-0">
                                                Round 2
                                            </div>
                                            <SaleCard
                                                startsIn="00:00:00"
                                                totalAllocation="TBA"
                                                startDate="TBA"
                                                endDate="TBA"
                                                price="TBA"
                                                minimumAllocation="TBA"
                                                percent="0"
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 d-flex flex-column align-items-center d-md-block">
                                            <div className="sale-tab child-tab mt-3 mt-md-0">
                                                Round 3
                                            </div>
                                            <SaleCard
                                                startsIn="00:00:00"
                                                totalAllocation="TBA"
                                                startDate="TBA"
                                                endDate="TBA"
                                                price="TBA"
                                                minimumAllocation="TBA"
                                                percent="0"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column align-items-center d-md-block">
                                    <div className="sale-tab mt-4 mt-xl-0">
                                        IDO
                                    </div>
                                    <SaleCard
                                        startsIn="00:00:00"
                                        totalAllocation="TBA"
                                        startDate="TBA"
                                        endDate="TBA"
                                        price="TBA"
                                        minimumAllocation="TBA"
                                        percent="0"
                                    />
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center align-items-center text-center mt-5 pt-5">
                                <div className="col-5">
                                    <Card className="claim-card">
                                        <Card.Body>
                                            <h2>CLAIM</h2>
                                            <div className="mt-3 mb-4 items p-3">
                                                <div className="item mb-2">
                                                    Your Total Tokens: <span> 0 COLT</span>
                                                </div>
                                                <div className="item">
                                                    Available for Claim: <span> 0 COLT</span>
                                                </div>
                                            </div>
                                            <Button className="claim-btn">CLAIM</Button>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="stake" title="Stake">
                    <div className="stake-section section">
                        <div className="container mb-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                                    <h3 className="mb-5">STAKE</h3>
                                    <StakeCard/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>

            <Footer/>
        </div>
    );
}
