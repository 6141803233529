import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, NavDropdown, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as Scroll from 'react-scroll';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

// Images
import EhereumSign from '../../assets/images/Demo-Project/ethereum-sign.png';
import BinanceSign from '../../assets/images/Demo-Project/binance-sign.png';
import americaFlag from '../../assets/images/flags/america.png';
import chinaFlag from '../../assets/images/flags/china.png';
import Logo from '../../assets/images/logo-2.png';

const Header = React.memo(() => {

    const [isWalletConnected, setWallet] = useState(false);
    const [selectedNetwork, setNetwork] = useState({name: 'Ethereum', sign: EhereumSign});
    const [language, setLanguage] = useState({name: 'EN', flag: americaFlag});
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
        setWindowWidth(window.innerWidth);
    }, []);

    const getDropDownDirection = () => {
        if (windowWidth < 991) {
            return "up";
        } else return "down";
    }

    return (
        <Navbar variant="dark" expand="lg" className="color-bg">
            <Container fluid className="pt-lg-3 ps-lg-5">
                <Navbar.Brand className="logo text-light">
                    <Link to="/">
                        <img className="img-fluid" src={Logo} alt=""/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll">
                    <FontAwesomeIcon icon={faBars} />
                </Navbar.Toggle>
                <Navbar.Collapse id="navbarScroll" className="text-center">
                    <Nav
                        className="me-auto my-2 my-lg-0 mt-4 mb-4"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link><Scroll.Link to="projects">Projects</Scroll.Link></Nav.Link>
                        <NavDropdown title="Bridge" id="navbarScrollingDropdown">
                            <NavDropdown.Item disabled>Coming Soon</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className="dashboard-text">
                            <Link to="/dashboard">
                                <h1>Dashboard</h1>
                            </Link>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className="navbar-right d-flex align-items-center justify-content-end">
                    <div className="d-flex flex-row align-items-center">
                        <DropdownButton
                            id="dropdown-basic-button"
                            className="me-2"
                            drop={getDropDownDirection()}
                            title={
                                <span className="d-flex align-items-center">
                                <img src={language.flag} alt=""/>{language.name}
                            </span>
                            }
                        >
                            <Dropdown.Item onClick={() => setLanguage({name: 'EN', flag: americaFlag})}>
                                English
                                <img src={americaFlag} alt=""/>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setLanguage({name: 'CH', flag: chinaFlag})}>
                                Chinese
                                <img src={chinaFlag} alt=""/>
                            </Dropdown.Item>
                        </DropdownButton>

                        <DropdownButton
                            id="dropdown-basic-button"
                            className="me-2"
                            drop={getDropDownDirection()}
                            title={
                                <span className="d-flex align-items-center">
                                <img src={selectedNetwork.sign} alt=""/>{selectedNetwork.name}
                            </span>
                            }
                        >
                            <Dropdown.Item onClick={() => setNetwork({name: 'Ethereum', sign: EhereumSign})}>
                                Ethereum
                                <img src={EhereumSign} alt=""/>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setNetwork({name: 'Binance Smart Chain', sign: BinanceSign})}>
                                Binance Smart Chain
                                <img src={BinanceSign} alt=""/>
                            </Dropdown.Item>
                        </DropdownButton>
                        {isWalletConnected ? (
                            <h6 className="mt-2">0x8rt95......75287</h6>
                        ) : (
                            <Button className="header-button" onClick={() => setWallet(true)}>Connect Wallet</Button>
                        )}
                    </div>
                </div>
            </Container>
        </Navbar>
    );
});

export default Header;
