import React from "react";
import { Badge, Button, Card, FormControl, InputGroup, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import './styles.css';
import signImage from "../../assets/images/Demo-Project/ethereum-sign.png";

const StakeCard = React.memo((props) => {

    const { onStakeLock } = props;

    return (
        <Card className="stake-card p-3">
            <Card.Body>
                <Tabs defaultActiveKey="stake" id="uncontrolled-tab-example" className="mb-4 stake-tabs">
                    <Tab eventKey="stake" title="Stake">
                        <div className="d-flex">
                            <div>
                                <h6>
                                    Stake COLT to participate in upcoming NFT Pre-Sale events.
                                    <span className="text-muted ms-2 text-decoration-underline">Learn more</span>
                                </h6>
                                <div className="amount mt-5 mb-5">
                                    <h6>Amount <span>0 COLT</span></h6>
                                    <InputGroup size="sm">
                                        <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="0.00"/>
                                        <div className="input-right">
                                            <Badge bg="secondary me-1">MAX</Badge>
                                            <img className="card-sign" alt="" src={signImage}/>
                                        </div>
                                    </InputGroup>
                                </div>
                                <Button className="stake-button" onClick={onStakeLock}>
                                    <FontAwesomeIcon className="me-2" icon={faLock} /> Stake and Lock
                                </Button>
                                <h6 className="notice text-muted mt-5 text-center">
                                    Your COLT tokens will be looked for 7 days. After that time, you're free to withdraw any time.
                                </h6>
                            </div>
                            <div className="d-flex flex-column justify-content-center ms-5">
                                <div className="staked-card mb-2">
                                    <h5 className="title">Staked</h5>
                                    <hr/>
                                    <h2 className="coins">0.000</h2>
                                </div>
                                <div className="staked-card">
                                    <h5 className="title">Unstaked</h5>
                                    <hr/>
                                    <h2 className="coins">0.000</h2>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="unstake" title="Unstake">
                        <h6>Unstake</h6>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    );
});

export default StakeCard;
