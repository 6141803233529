import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";

export default function PageNotFound() {

    const navigate = useNavigate();

    return (
        <div id="app-content">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center mt-5 mb-5 pt-5 pb-5">
                <h1 className="mb-4">404</h1>
                <h3 className="mb-3">Oops, page not found</h3>
                <p className="mb-5">Sorry, page you requested does not exist or was deleted!</p>
                <Button className="button-primary" onClick={() => navigate('/')}>Return to Home</Button>
            </div>
        </div>
    )
}
