import React from "react";
import { Badge, Button, Card } from "react-bootstrap";
import './styles.css';
import ProjectPlaceholder from '../../assets/images/Demo-Project/project-placeholder.png';
import AvatarPlaceholder from '../../assets/images/Demo-Project/avatar-placeholder.png';

const ProjectCard = React.memo((props) => {
    const { name, currency, totalItems, access, signImage, projectImage, avatar, status, onClick } = props;

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <Card className="project-card" onClick={onClick}>
                <Card.Img variant="top" className="img-fluid" src={projectImage} />
                <Card.Body>
                    <Badge bg="light" text="dark">{status}</Badge>
                    <div className="card-avatar">
                        <img className="img-fluid" alt="" src={avatar}/>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-4">
                        <div className="mt-4">
                            <Card.Title>{name}</Card.Title>
                            <Card.Text>{currency}</Card.Text>
                        </div>
                        <div className="card-sign mt-4">
                            <img alt="" src={signImage}/>
                        </div>
                    </div>
                    <h6 className="card-points">Total Items <span>{totalItems}</span></h6>
                    <h6 className="card-points">Access <span>{access}</span></h6>
                </Card.Body>
            </Card>
            <Button onClick={onClick} className="button-primary mt-4 mb-5">Click to see DEMO</Button>
        </div>
    );
});

export default ProjectCard;

ProjectCard.defaultProps = {
    avatar: AvatarPlaceholder,
    projectImage: ProjectPlaceholder,
    onClick: null,
};
