import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDiscord,
    faMedium,
    faTelegram,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import Logo from "../../assets/images/logo-2.png";

const Footer = React.memo(() => {
    return (
        <footer>
            <div className="container pt-5 pb-4">
                <div className="row">
                    {/*<div className="col-md-6 col-lg-8">*/}
                    {/*    <h6 className="section-sub-heading mt-2 text-center text-md-start">*/}
                    {/*        NEVER WANT TO MISS A SALE?*/}
                    {/*    </h6>*/}
                    {/*    <h1 className="section-heading mb-5 text-center text-md-start">*/}
                    {/*        Sign up to Collectivly Newsletter*/}
                    {/*    </h1>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-6 col-lg-4">*/}
                    {/*    <InputGroup size="sm" className="mb-3">*/}
                    {/*        <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Email Address"/>*/}
                    {/*        <Button className="button-primary">Subscribe</Button>*/}
                    {/*    </InputGroup>*/}
                    {/*</div>*/}
                    <div className="col-md-12 col-lg-6">
                        <div className="row">
                            <div className="footer-logo col-md-12 col-lg-3 d-flex flex-column text-center text-lg-start align-items-center align-items-lg-start mb-5 mt-5 mt-md-0">
                                <img src={Logo} alt=""/>
                            </div>
                            <div className="col-md-12 col-lg-3 d-flex flex-column text-center text-lg-start align-items-center align-items-lg-start">
                                <h6>Collectivly</h6>
                                <ul>
                                    <li><Link to="/">About us</Link></li>
                                    <li><Link to="/">Documentation</Link></li>
                                    <li><Link to="/">Apply for Launch</Link></li>
                                    <li><a href="https://drive.google.com/file/d/1j-hXWlUaEyTF-qD_yO52YOWzBsSLrLbb/view" target="_blank">Pitch Deck</a></li>
                                </ul>
                            </div>
                            <div className="col-md-12 col-lg-3 d-flex flex-column text-center text-lg-start align-items-center align-items-lg-start">
                                <h6>Help</h6>
                                <ul>
                                    <li><a href="mailto:contact@collectivly.io">Contact</a></li>
                                    <li><Link to="/terms">Terms & Conditions</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                    <li><Link to="/">Support</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 d-flex flex-lg-column justify-content-center justify-content-lg-end mb-5">
                        <div className="social-icons col-md-12 col-lg-6 d-flex justify-content-center justify-content-lg-start align-self-lg-end">
                            <a href="https://t.me/Collectivly_Official" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-3 text-muted" icon={faTelegram} />
                            </a>
                            <a href="https://discord.com/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-3 text-muted" icon={faDiscord} />
                            </a>
                            <a href="https://twitter.com/Collectivly_IO" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-3 text-muted" icon={faTwitter} />
                            </a>
                            <a href="https://collectivly.medium.com/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-3 text-muted" icon={faMedium} />
                            </a>
                            <a href="https://www.youtube.com/channel/UCekE4R6YL9s3Duv5hFY0RDw" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-3 text-muted" icon={faYoutube} />
                            </a>
                        </div>
                    </div>
                    <div className="d-flex flex-column-reverse flex-lg-row justify-content-lg-between">
                        <div className="col-md-12 col-lg-6 mt-5 footer-text text-muted d-flex justify-content-center justify-content-lg-start mb-5 mb-lg-0">
                            Copyright © 2022 Collectivly. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
